import { api } from "../../config";

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const fetchToken = async (searchString) => {
  if (!searchString) {
    console.error('No search string');
  }
  try {
    const res = await fetch(`${api.baseUrl}/vipps/token${searchString}`)
    const token = await res.json();
    if (token.id_token) {
      const parsedToken = parseJwt(token.id_token);
      token.expires_at = Date.now() + (token.expires_in * 1000);
      token.id_expires_at = parsedToken.exp * 1000;
      localStorage.setItem('token', JSON.stringify(token));
    }
    return token;
  } catch (err) {
    console.error('Fetch token error', err);
  }
}

export const fetchLoginUrl = async () => {
  try {
    const res = await fetch(`${api.baseUrl}/vipps/login`);
    const data = await res.json();
    return data;
  } catch (err) {
    console.error('Fetch login url error', err);
  }
}

import './placeCard.css';
import { ReactComponent as Svg } from '../../img/300x200.svg';

export const PlaceCards = ({ place }) => {
  let image;
  try {
    image = <img alt='Bassengbilde' src={require(`../../img/${place.name.replaceAll(' ', '_').toLowerCase()}.jpg`)} />;
  } catch (err) {
    image = <Svg />;
  }

  return (
    <div className='place-card'>
      <div className="top">
        <h3>{place.name}</h3>
        <p>{`${place.address}, ${place.zipCode} ${place.place}`}</p>
      </div>
      <div className="middle">
        {image}
      </div>
      <div className="bottom">
        <a href={`/kurs/${place._id}`}>{place.courses.length ? "Se ledige kurs" : "Les informasjon"}</a>
      </div>
    </div>
  );
}

import { useState } from "react";
import { Modal } from "../Generic/Modal";
import './placeModal.css'
import Button from "../../Buttons/Button";

export const PlaceModal = ({ options }) => {
  const data = options.data ? options.data() : {};
  const [formData, setFormData] = useState(data);

  // if (options.data) setFormData(options.data());

  const defaultOptions = {
    title: formData.name ? 'Rediger kurssted' : 'Legg til kurssted',
    closeOnOutsideClick: false,
  };

  // if (options.editPlace && !formData) return;

  return (
    <Modal options={{ ...defaultOptions, ...options }}>
      <form id="new-place-form">
        <div className="name">
          <label htmlFor="name">Navn</label>
          <input id="name" required autoComplete='off' type="text" value={formData.name ? formData.name : ''} onChange={e => setFormData({ ...formData, name: e.target.value })} />
        </div>
        <div className="address">
          <label htmlFor="address">Adresse</label>
          <input id="address" required autoComplete='off' type="text" value={formData.address ? formData.address : ''} onChange={e => setFormData({ ...formData, address: e.target.value })} />
        </div>
        <div className="zip-place">
          <div className="zipcode">
            <label htmlFor="zipcode">Postnr.</label>
            <input id="zipcode" required autoComplete='off' pattern="[0-9]{4}" type="text" value={formData.zipCode ? formData.zipCode : ''} maxLength={4} onChange={e => setFormData({ ...formData, zipCode: e.target.value })} />
          </div>
          <div className="place">
            <label htmlFor="place">Sted</label>
            <input id="place" required autoComplete='off' type="text" value={formData.place ? formData.place : ''} onChange={e => setFormData({ ...formData, place: e.target.value })} />
          </div>
        </div>
        <div className="info">
          <label htmlFor="info">Informasjon</label>
          <textarea id="info" value={formData.info ? formData.info.replace(/<[^>]*>/g, '\n') : ''} onChange={e => setFormData({ ...formData, info: e.target.value })}></textarea>
        </div>
        <div className="published">
          <label htmlFor="published">Publisert</label>
          <input id="published" type="checkbox" checked={formData.published === true || formData.published === false ? formData.published : false} onChange={e => setFormData({ ...formData, published: e.target.checked })} />
        </div>
        <div className="actions">
          <div className="left">
            <Button className="btn" type="reset" onClickFn={e => {options.close()}}>Avbryt</Button>
            <p className='pointer text-delete-color' onClick={() => {
              options.deletePlace(formData._id);
            }}>Slett</p>
          </div>
          <div className="right">
            <Button
              className="btn"
              bgc="#0146F6"
              color="#fff"
              type="submit"
              onClickFn={e =>{
                e.preventDefault();
                if (options.save(formData)) options.close();
              }}
            >Lagre</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

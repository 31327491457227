import { Link } from 'react-router-dom';
import { vippsOrderStatusTranslation, weekdayHelper } from '../../functions/helpers';
import { WebhookEventForOrderCard } from '../Vipps/Webhooks/WebhookEventForOrderCard';
import './orderCard.css';

export const OrderCard = ({ order }) => {

  return (
    <div className="order-card">
      <div className="top">
        <div className="header">
          <h3>Bestilling</h3>
        </div>
        <div className="order-data">
          <p>Siste status: <strong>{vippsOrderStatusTranslation(order.status)}</strong></p>
          <p>Opprettet: {new Date(order.createdAt).toLocaleString()}</p>
          <p>Sist endret: {new Date(order.updatedAt).toLocaleString()}</p>
          <p>Betalingsmåte: {order.provider}</p>
          <p>Vår referanse: {order.reference}</p>
          <p>Kundens referanse: {order.humanReference}</p>
          <p>Totalt beløp: {order.amount},-</p>
        </div>
        {order.additionalInfo && <div className="order-additional-info">
          <p>Kunde: <Link to={`/profil/${order.additionalInfo.profile}`}>{order.additionalInfo.user._id ? `${order.additionalInfo.user.firstName} ${order.additionalInfo.user.lastName}` : order.additionalInfo.user}</Link></p>
          <p>Barn: <Link to={`/profil/${order.additionalInfo.profile}`}>{order.additionalInfo.child._id ? `${order.additionalInfo.child.firstName} ${order.additionalInfo.child.lastName}` : order.additionalInfo.child}</Link></p>
          <p>Kurs: {order.additionalInfo.course._id ?
            `${order.additionalInfo.course.place.name} ${order.additionalInfo.course.participantsAge} ${order.additionalInfo.course.experienceLevel} ${weekdayHelper(new Date(order.additionalInfo.course.dates[0]).getDay()).toLocaleLowerCase()}er fra ${new Date(order.additionalInfo.course.dates[0]).toLocaleDateString()} kl. ${order.additionalInfo.course.timeFrom}`
          :
            order.additionalInfo.course}
          </p>
        </div>}
      </div>
      <div className="bottom">
        <p><strong>Hendelser:</strong></p>
        <div className="events-list">
          {order.created && <WebhookEventForOrderCard event={order.created} />}
          {order.authorized && <WebhookEventForOrderCard event={order.authorized} />}
          {order.cancelled && <WebhookEventForOrderCard event={order.cancelled} />}
          {order.captured && <WebhookEventForOrderCard event={order.captured} />}
          {order.refunded && <WebhookEventForOrderCard event={order.refunded} />}
          {order.expired && <WebhookEventForOrderCard event={order.expired} />}
          {order.terminated && <WebhookEventForOrderCard event={order.terminated} />}
          {order.aborted && <WebhookEventForOrderCard event={order.aborted} />}
        </div>
      </div>
    </div>
  );
}

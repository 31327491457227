import './errorModal.css';
import Button from "../../Buttons/Button";
import { Modal } from "../Generic/Modal";

export const ErrorModal = ({ options, children }) => {

  return (
    <Modal options={{ ...options, title: 'Error' }}>
      {children}
      <div className="actions">
        <Button onClickFn={options.close}>Greit</Button>
      </div>
    </Modal>
  );
}

import { OrderCard } from '../Cards/OrderCard';
import './ordersList.css';

export const OrdersItem = ({ order, showBgColors }) => {

  if (!order) return;

  let statusClass = '';
  if (order.status === 'captured' || order.status === 'refunded') statusClass = ' success';
  if (order.status === 'authorized' || order.status === 'expired') statusClass = ' warning';
  if (order.status === 'cancelled' || order.status === 'terminated' || order.status === 'aborted') statusClass = ' error';

  return (
    <div className={`orders-item box${showBgColors ? statusClass : ''}`}>
      <OrderCard order={order} />
    </div>
  );
}

import VippsButton from '../Buttons/VippsButton';
import { useState } from 'react';
import './vipps-login.css';
import { fetchLoginUrl } from '../../functions/fetch';

const VippsLogin = () => {
  const [loading, setLoading] = useState(false);
  
  const clickedVippsLoginButton = async props => {
    if (loading) return;
    if (!window.location.href.includes('login')) {
      sessionStorage.setItem('urlBeforeLogin', window.location.href);
    }
    setLoading(true);
    const loginObj = await fetchLoginUrl();
    setLoading(false);
    if (!loginObj) {
      return window.location.replace('/');
    };
    window.location.replace(loginObj.loginUrl);
  }

  return (
    <div className="vipps-login" onClick={clickedVippsLoginButton}>
      <VippsButton stretched="true" loading={loading} />
    </div>
  );
}

export default VippsLogin;

import './bigPlaceCard.css';
// import { ReactComponent as Svg } from '../../img/600x260.svg';

export const BigPlaceCard = ({ place }) => {
  let image;
  try {
    image = <img  alt='Bassengbilde' src={require(`../../img/${place.name.replaceAll(' ', '_').toLowerCase()}.jpg`)} />;
  } catch (err) {
    image = <img src={require('../../img/600x260.svg').default} alt="Bassengbilde" />;
  }

  return (
    <div className='big-place-card box'>
      <div className="place-image">
        {image}
      </div>
      <div className="course-info">
        <h3>{place.name}</h3>
        <p><strong>Adresse</strong>: {place.address}, {place.zipCode} {place.place}</p>
        <p className="pre-wrap"><strong>Informasjon</strong>: {place.info}</p>
      </div>
    </div>
  );
}


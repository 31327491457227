import { api } from "../../config";
import { fetchWithOptionalVippsToken, fetchWithVippsToken } from "../auth";
import { sortDatesAsc } from "../helpers";

export const fetchCoursesForPlace = async (placeId) => {
  if (!placeId || placeId.length !== 24) return;
  try {
    const res = await fetchWithOptionalVippsToken(`${api.baseUrl}/places/${placeId}/courses`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const editCourse = async (editedCourse) => {
  editedCourse.dates.sort(sortDatesAsc);
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${editedCourse._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedCourse)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const addCourse = async (newCourse) => {
  newCourse.dates.sort(sortDatesAsc);
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newCourse)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteCourse = async (id) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${id}`, {
      method: 'DELETE'
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const addParticipant = async (userId, courseId) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${courseId}/participants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: userId })
    });
    if (res.status !== 200) throw new Error('Something went wrong');
    const data = await res.json();
    return data;
  } catch (err) {
    // console.log(err);
    throw new Error('Error adding participant');
  }
}

export const deleteParticipant = async (userId, courseId) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${courseId}/participants/${userId}`, {
      method: 'DELETE'
    });
    if (res.status !== 200) throw new Error('Something went wrong');
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
    throw new Error('Error deleting participant');
  }
}

export const moveParticipant = async (userId, fromCourseId, toCourseId) => {
  const addParticipantResult = await addParticipant(userId, toCourseId);
  if (!addParticipantResult) {
    console.log('Error adding participant to course');
    return false;
  }
  const deleteParticipantResult = await deleteParticipant(userId, fromCourseId);
  if (!deleteParticipantResult) {
    console.log('Error deleting participant from course');
    return false;
  }
  return true;
}

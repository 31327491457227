import './bubblesGroup.css';
import { Bubble, BubbleGradient } from './BubbleSVG';

const BubblesGroup = () => {
  return (
    <div className='bubble-group'>
      <Bubble diameter={40} fill='#0146f6' />
      <BubbleGradient diameter={110} gradient={{ start: '#05D0DB', stop: '#0063F9' }} />
      <Bubble diameter={60} fill='#0146f6' />
      <Bubble diameter={40} fill='#0146f6' />
      <Bubble diameter={60} fill='#0146f6' />
      <Bubble diameter={200} fill='#0146f6' />
      <Bubble diameter={60} fill='#0146f6' />
      <Bubble diameter={100} fill='#0146f6' />
      <BubbleGradient diameter={150} gradient={{ start: '#05D0DB', stop: '#0063F9' }} />
      <BubbleGradient diameter={60} gradient={{ start: '#05D0DB', stop: '#0063F9' }} />
      <Bubble diameter={40} fill='#0146f6' />
      <Bubble diameter={40} fill='#0146f6' />
    </div>
  );
}

export default BubblesGroup;
// background: linear - gradient(217deg, #05D0DB 15.39 %, #0063F9 70.64 %), #0146F6;
import './customCheckbox.css';

export const CustomCheckbox = ({ options }) => {

  const classes = [];

  if (options.disabled) {
    classes.push('disabled');
    options.onChange = () => {};
  }

  return <div className="custom-checkbox">
    <input
      className={classes.join(' ')}
      id={`cc-${options.value}`}
      type="checkbox"
      checked={options.checked}
      value={options.value}
      onChange={options.onChange}
      disabled={options.disabled}
    />
    <label
      className={classes.join(' ')}
      htmlFor={`cc-${options.value}`}
    >
      {options.label}
    </label>
  </div>;
};

import "./frontPageWave.css";

const FrontPageHeaderShape = () => {
  return (
    <div className="wave-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2560 720"
      >
        <path
          d="M0 690s123.37-107.14 429.13-113c208.55-4 806.71 67 1132.98 67 355.44 0 450.03-55.36 392.03-239-40.1-127-216.56-405-216.56-405H0v690z"
          fill="#eefbff"
        />
      </svg>
    </div>
  )
}

export default FrontPageHeaderShape;

/*
  M
  0 95 C 10 60 15 80
  10 78 C 10 67 50 77
  62 75 C 85 70 70 5
  70 0
  L 0 0
  Z



  
    // <svg
    //   max-height="100%"
    //   width="100%"
    //   viewBox="0 0 100 100"
    //   preserveAspectRatio="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M
    //       0 97.222 C 3.100 91.010 5.500 87.402
    //       12.466 86.836 C 19.496 86.261 47.693 98.544
    //       63.621 93.726 C 80.989 88.47375 70.354 28.694
    //       61.252 0
    //       L 0 0
    //       Z"
    //     fill="#eefbff"
    //     paintOrder="fill"
    //     vectorEffect="non-scaling-stroke"
    //   />
    // </svg>
*/

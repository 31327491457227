import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import './login.css';
import VippsLogin from '../../Vipps/Login';

const LoginPage = () => {
  document.title = 'Logg inn - Badenymfene Babysvømming';
  return (
    <div className="content-margined">
      <div className='login-page'>
        <TitleWithSeparator title="Logg inn" />
        <div className="description">
          <p>Vi tilbyr foreløpig kun innlogging via Vipps</p>
        </div>
        <div className="login-section">
          <div className="social-login-buttons">
            <h1>Logg inn med:</h1>
            <VippsLogin />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

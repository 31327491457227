import ContactForm from '../../Contact/ContactForm';
import { Faqs } from '../../Faqs/Faqs';
import './contact.css';

const ContactPage = () => {
  document.title = 'Kontakt - Badenymfene Babysvømming';
  return (
    <div className="content-margined">
      <div className='contact'>
        <ContactForm />
        <Faqs />
      </div>
    </div>
  );
}

export default ContactPage;

import { api } from "../../config";

export const sendContactForm = async (formData) => {
  try {
    const res = await fetch(`${api.baseUrl}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

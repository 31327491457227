import './personCard.css';
let personImg;

export const PersonCard = ({ user, children }) => {

  return (
    <div className="person-card">
      {personImg && 
        <div className="top">
          <img src={personImg} alt={user.name || 'Instruktør'} />
        </div>
      }
      <div className="bottom">
        {children}
      </div>
    </div>
  );
}

export default PersonCard;

import TitleWithSeparator from '../Separator/TitleWithSeparator';
import './aboutCompany.css';

const AboutCompany = (props) => {

  return (
    <div className="about-company">
      <TitleWithSeparator title="Om Badenymfene" />
      <div className="description">
        <p>Badenymfene babysvømming ble startet i 2003. Siden den gang har vi hatt baby og småbarnsvømming hovedsakelig i alderen 0 til 10 år.
          Badenymfene er i utvikling og vokser stadig!
          Badenymfenes mål er å lære flere barn å svømme. Vi ønsker å gjøre barn trygge/kjent i vannet allerede som babyer. Svømming skal være gøy, og lærerikt.
          Vi har flere kursrunder i løpet av et år.
          Badenymfene babysvømming er et firma hvor instruktører utdannes hos Norges Livreddninsselskap og Norges svømmeforbund. Noen av våre instruktører har mange års erfaring som babysvømmeinstruktør.
          Alle instruktørene må gjennomgå en årlig livredningsprøve, og videreutdanne seg gjennom seminarer.</p>
      </div>
    </div>
  );
}

export default AboutCompany;

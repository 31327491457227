import { useState, useContext } from "react";
import { LoggedInUserContext } from "../../../context";
import { Modal } from "../Generic/Modal";
import './courseModal.css'
import Button from "../../Buttons/Button";
// import { fetchInstructors } from "../../../functions/fetch";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { sortDatesAsc } from "../../../functions/helpers";
import { CustomSelect } from "../../Select/CustomSelect";
// import { isDST } from "../../../functions/helpers";

const months = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"];
const weekdays = ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"];

export const CourseModal = ({ options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const data = options.course ? options.course : {};
  const [formData, setFormData] = useState(data);
  // const [instructors, setInstructors] = useState([]);

  const defaultOptions = {
    title: formData._id ? 'Rediger Kurs' : 'Legg til Kurs',
    closeOnOutsideClick: false,
  };

  if (!loggedInUser || !loggedInUser.roles.includes('admin')) return null;

  if (!formData) setFormData({});

  return (
    <Modal options={{ ...defaultOptions, ...options }}>
      <form id="edit-course-form">
        <div className="data">
          <div className="age">
            <label htmlFor="age">Alder</label>
            <input id="age" required autoComplete='off' type="text" value={formData.participantsAge ? formData.participantsAge : ''} onChange={e => setFormData({ ...formData, participantsAge: e.target.value })} />
          </div>
          <div className="experience-level">
            <label htmlFor="experience-level">Erfaringsnivå</label>
            <CustomSelect
              style={{ fontSize: '13.333px' }}
              name="experience-level"
              id="experience-level"
              defaultValue={formData.experienceLevel || "Velg nivå"}
              onChange={e => setFormData({ ...formData, experienceLevel: e.target.value })}
            >
              <option value="Velg nivå" disabled>Velg nivå</option>
              <option value="Nybegynner">Nybegynner</option>
              <option value="Viderekommen">Viderekommen</option>
              <option value="Søskensvømming">Søskensvømming</option>
              <option value="Nybegynner/Viderekommen">Nybegynner/Viderekommen</option>
              <option value="Arrangement">Arrangement</option>
            </CustomSelect>
          </div>
          <div className="time">
            <label htmlFor="time">Tid</label>
            <div className="time-inputs">
              <input id="time" required autoComplete='off' type="time" value={formData.timeFrom ? formData.timeFrom : ''} onChange={e => setFormData({ ...formData, timeFrom: e.target.value })} /> - <input id="time" required autoComplete='off' type="time" value={formData.timeTo ? formData.timeTo : ''} onChange={e => setFormData({ ...formData, timeTo: e.target.value })} />
            </div>
          </div>
          <div className="dates">
            <label htmlFor="dates">Datoer</label>
            <DatePicker
              id="dates"
              value={formData.dates ? formData.dates : []}
              onChange={(_, { validatedValue }) => {
                // console.log("🚀 ~ file: CourseModal.js:58 ~ CourseModal ~ validatedValue:", validatedValue);
                // const isDSTNow = isDST(new Date());
                // const offset = isDSTNow ? "+02:00" : "+01:00";
                // console.log("🚀 ~ file: CourseModal.js:63 ~ CourseModal ~ offset:", offset);
                const dates = validatedValue.map(date => new Date(`${date} ${formData.timeFrom ? formData.timeFrom : '12:00'}`));
                dates.sort(sortDatesAsc);
                // console.log("🚀 ~ file: CourseModal.js:59 ~ CourseModal ~ dates:", dates.map(d => d.toISOString()));
                setFormData({ ...formData, dates: dates });
              }}
              containerClassName="date-picker-container"
              inputClass="date-picker"
              containerStyle={{ display: 'flex', flexDirection: 'column' }}
              months={months}
              weekDays={weekdays}
              format="YYYY-MM-DD"
              // numberOfMonths={2}
              weekStartDayIndex={1}
              multiple
              plugins={[
                <DatePanel header="Datoer" />
              ]}
            />
            {/* <input id="dates" required autoComplete='off' type="number" value={!formData.dates || formData.dates.length <= 0 ? 1 : formData.dates.length} placeholder='Antall ganger' min={1} max={50} onChange={e => setFormData({ ...formData, dates: formData.dates.length > 0 ? formData.dates : [new Date()] })} /> */}
          </div>
          <div className="price">
            <label htmlFor="price">Pris</label>
            <input id="price" required autoComplete='off' type="number" min={0} step={25} value={formData.price ? formData.price : 0} onChange={e => setFormData({ ...formData, price: e.target.value })} />
          </div>
          <div className="maximum-participants">
            <label htmlFor="maximum-participants">Maksimalt antall deltagere</label>
            <input id="maximum-participants" required autoComplete='off' type="number" min={0} value={formData.maximumParticipants ? formData.maximumParticipants : 0} onChange={e => setFormData({ ...formData, maximumParticipants: e.target.value })} />
          </div>
          <div className="instructors">
            <label htmlFor="instructors">Instruktør</label>
            <CustomSelect
              style={{ fontSize: '13.333px' }}
              name="instructors"
              id="instructors"
              defaultValue={formData.instructors && formData.instructors.length > 0 ? formData.instructors[0] : "0"}
              onChange={e => setFormData({ ...formData, instructors: [e.target.value] })}
            >
              <option value="0" disabled>Velg instruktør</option>
              <option value="1">Foreløpig ukjent</option>
              {options.instructors.map(instructor => <option key={instructor.id} value={instructor.id}>{instructor.name}</option>)}
            </CustomSelect>
          </div>
          <div className="info">
            <label htmlFor="info">Informasjon</label>
            <textarea id="info" value={formData.info ? formData.info.replace(/<[^>]*>/g, '\n') : ''} onChange={e => setFormData({ ...formData, info: e.target.value })}></textarea>
          </div>
          <div className="published">
            <label htmlFor="published">Publisert</label>
            <input id="published" type="checkbox" checked={formData.published === true || formData.published === false ? formData.published : false} onChange={e => setFormData({ ...formData, published: e.target.checked })} />
          </div>
        </div>
        <div className="actions">
          <div className="left">
            <Button className="btn" type="reset" onClickFn={e => {
              setFormData({});
              options.close();
            }}>Avbryt</Button>
            <p className='pointer text-delete-color' onClick={() => {
              options.deleteCourse(formData._id);
            }}>Slett</p>
          </div>
          <div className="right">
            <Button
              className="btn"
              bgc="#0146F6"
              color="#fff"
              type="submit"
              onClickFn={e =>{
                e.preventDefault();
                if (options.save(formData)) options.close();
              }}
            >Lagre</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

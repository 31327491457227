import { vippsOrderStatusTranslation } from "../../../functions/helpers";

export const WebhookEventForOrderCard = ({ event }) => {

  return (
    <div className="webhook-event">
      <p>Status: {vippsOrderStatusTranslation(event.name)}</p>
      <p>Vipps referanse-ID: {event.pspReference}</p>
      <p>Beløp: {event.amount.value / 100} {event.amount.currency}</p>
      <p>Dato: {new Date(event.timestamp).toLocaleString()}</p>
    </div>
  );
}

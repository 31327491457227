import { useEffect, useState } from 'react';
import './ordersList.css';
import { getAllOrders } from '../../functions/fetch';
import { OrdersItem } from './OrdersItem';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';

export const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [showBgColors, setShowBgColors] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await getAllOrders();
        setOrders(res);
      } catch (err) {
        console.log(err);
      }
    }
    fetchOrders();
  }, []);

  return (
    <div className="orders-list-wrapper">
      <CustomCheckbox options={{ label: 'Vis statusfarger', checked: showBgColors, onChange: () => setShowBgColors(!showBgColors) }} />
      <div className='orders-list'>
        {orders && orders.length > 0 ? 
          orders.map((order, index) => (<OrdersItem order={order} key={index} showBgColors={showBgColors} />))
        :
          <div className='no-orders'>Ingen ordre</div>}
      </div>
    </div>
  );
}

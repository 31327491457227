import './coursesHistoryGrid.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { weekdayHelper } from '../../../functions/helpers';
import { CoursesHistoryGridRowExpanded } from './CoursesHistoryGridRowExpanded';
import { EditParticipantModal } from '../../Modal/AddChild/EditParticipantModal';

export const CoursesHistoryGridRow = ({ options, course }) => {
  const [expanded, setExpanded] = useState(false);
  const [openEditParticipantModal, setOpenEditParticipantModal] = useState(false);
  
  if (!course) return null;

  if (!options.instructors) options.instructors = [];
  const instructorsForCourse = course.instructors && course.instructors.length > 0 ? course.instructors.map(instructor => options.instructors.find(i => i.id === instructor)) : [];
  
  let status = 'Påmeldt';
  const now = Date.now();
  if (now >= new Date(course.dates[0]).getTime() && now <= new Date(course.dates[course.dates.length - 1]).getTime()) {
    status = 'Pågår';
  } else if (now >= new Date(course.dates[course.dates.length - 1]).getTime()) {
    status = 'Fullført';
  }

  const duration = course.dates && course.dates.length > 0 ? course.dates.length : 0;

  return (
    <div className={`courses-history-grid-wrapper`}>
      <div className="courses-history-grid-row" style={status === 'Fullført' ? { opacity: 0.4 } : {}}>
        <div className="pointer" onClick={() => setExpanded(!expanded)}>
          {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </div>
        <div className="user" onClick={() => setExpanded(!expanded)}>
          <p>{options.user.firstName}</p>
        </div>
        <div className="place" onClick={() => setExpanded(!expanded)}>
          <p>{course.place.name}</p>
        </div>
        <div className="age" onClick={() => setExpanded(!expanded)}>
          <p>{course.participantsAge}</p>
        </div>
        <div className="experience-level" onClick={() => setExpanded(!expanded)}>
          <p>{course.experienceLevel}</p>
        </div>
        <div className="time" onClick={() => setExpanded(!expanded)}>
          <p>{course.timeFrom} - {course.timeTo}</p>
        </div>
        <div className="day" onClick={() => setExpanded(!expanded)}>
          <p>{course.dates && course.dates.length > 0 ? weekdayHelper(new Date(course.dates[0]).getDay()) : weekdayHelper(new Date().getDay())}</p>
        </div>
        <div className="dates" onClick={() => setExpanded(!expanded)}>
          <p>{course.dates && course.dates.length > 0 ? new Date(course.dates[0]).toLocaleDateString() : new Date().toLocaleDateString()}</p>
        </div>
        <div className="duration" onClick={() => setExpanded(!expanded)}>
          <p>{duration} {duration > 1 ? 'ganger' : 'gang'}</p>
        </div>
        <div className="status" onClick={() => setExpanded(!expanded)}>
          <p>{ status }</p>
        </div>
      </div>
      {expanded && <CoursesHistoryGridRowExpanded
        course={course}
        options={{
          user: options.user,
          instructorsForCourse,
          openEditParticipantModal: () => setOpenEditParticipantModal(true)
        }}
      />}
      {openEditParticipantModal && <EditParticipantModal options={{
        isOpen: openEditParticipantModal,
        close: () => setOpenEditParticipantModal(false),
        course,
        participant: options.user,
        updateHistory: options.updateHistory
      }} />}
    </div>
  );
}

import { useContext } from "react";
import { Modal } from "../Generic/Modal";
import './addChildModal.css'
import { LoggedInUserContext } from "../../../context";
import { AddChild } from "./AddChild";

export const AddChildModal = ({ options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (!loggedInUser) return null;

  return (
    <Modal options={{ ...options, title: 'Legg til/endre barn' }}>
      <div className="child-modal-content">
        <AddChild options={{
          child: options.child,
          finishEditing: options.close,
          deleteChild: options.deleteChild,
          saveNewChild: options.saveNewChild,
          updateChild: options.updateChild
        }} />
      </div>
    </Modal>
  );
}

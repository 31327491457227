export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim;

export const weekdayHelper = weekday => {
  switch (weekday) {
    case 0:
      return "Søndag";
    case 1:
      return "Mandag";
    case 2:
      return "Tirsdag";
    case 3:
      return "Onsdag";
    case 4:
      return "Torsdag";
    case 5:
      return "Fredag";
    case 6:
      return "Lørdag";
    default:
      console.error("Ukjent dag");
      return "Ukjent dag";
  }
}

export const isDST = (d) => {
  if (typeof d !== 'object') d = new Date(d);
  if (d instanceof Date === false) throw new Error('Invalid date');
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

export const sortDatesAsc = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export const sortDatesDesc = (a, b) => {
  if (a < b) return 1;
  if (a > b) return -1;
  return 0;
}

export const addZero = (number) => {
  if (number < 10) return `0${number}`;
  return number;
}

export const splitUrlsInString = (text) => {
  const textArray = text.split(urlRegex);
  return textArray;
}

export const vippsOrderStatusTranslation = (status) => {
  switch (status.toUpperCase()) {
    case 'CREATED':
      return 'Opprettet';
    case 'AUTHORIZED':
      return 'Godkjent av kunde';
    case 'TERMINATED':
    case 'CANCELLED':
      return 'Avbrutt av Badenymfene';
    case 'ABORTED':
      return 'Avbrutt av kunde';
    case 'EXPIRED':
      return 'Utløpt';
    case 'REFUNDED':
      return 'Refundert';
    case 'CAPTURED':
      return 'Betalt';
    default:
      return `Ukjent (${status})`;
  }
}

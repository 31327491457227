import './simpleSpinner.css';

export const SimpleSpinner = ({ text }) => {
  return (
    <div className="simple-spinner">
      <div className="spinner x2"></div>
      {text && <p>{text}</p>}
    </div>
  );
}

import { Link } from 'react-router-dom';
import BubblesGroup from '../svg/BubblesGroup';
import Button from '../Buttons/Button';
import './frontPageHeader.css';
import FrontPageWave from '../svg/FrontPageWave';
import { useContext } from 'react';
import { ModalOpenContext } from '../../context';

const FrontPageHeader = () => {
  const { setModalIsOpen } = useContext(ModalOpenContext);
  return (
    <div className='front-page-header'>
      <div className="front-page-header-svg">
        <FrontPageWave />
        <BubblesGroup />
      </div>
      <div className="content">
        <h1>Lær barnet ditt trygghet i vannet</h1>
        <p>Svømming skal være gøy og lærerikt. Gjør barnet ditt kjent og trygg i vannet allerede som baby, samtidig som dere skaper nye minner og tillit sammen</p>
        <div className="actions">
          <Link to="/kurs"><Button classes="front-page-btn" bgc="#0146F6" color="#fff">Bestill kurs</Button></Link>
          <Link to="/om-oss"><Button classes="front-page-btn" bgc="#EEFBFF" onClickFn={() => setModalIsOpen(true)}>Les mer om babysvømming</Button></Link>
        </div>
      </div>
    </div>
  );
}

export default FrontPageHeader;

import { Link, useNavigate } from 'react-router-dom';
import './notFound.css';

export const NotFound = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="error box not-found">
      <p>{children || 'Vi kan dessverre ikke finne siden du leter etter'}</p>
      <div className="links">
        <a href="#" onClick={e => {
          e.preventDefault();
          navigate(-1);
        }}>Gå tilbake</a>
        <Link to="/" className="link">Til forsiden</Link>
      </div>
    </div>
  );
}

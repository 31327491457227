import { useContext, useEffect, useState } from 'react';
import { FaqList } from '../Lists/FaqList';
import TitleWithSeparator from '../Separator/TitleWithSeparator';
import './faqs.css';
import { addFaq, deleteFaq, editFaq, getFaqs } from '../../functions/fetch';
import { LoggedInUserContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const Faqs = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    const fetchFaqList = async () => {
      const res = await getFaqs();
      if (!res) console.error('Error fetching faqs', res);
      setFaqList(res);
    };

    fetchFaqList();
  }, []);

  const newFaq = () => {
    setFaqList([ ...faqList, {
      _id: '',
      question: '',
      answer: ''
    }]);
  }

  const saveNewFaq = async (newFaq) => {
    const data = await addFaq(newFaq);
    if (data) setFaqList([ ...faqList.map(faq => newFaq._id === '' ? data : faq) ]);
    // if (data) setFaqList([ ...faqList.map(faq => {
    //   console.log("🚀 ~ saveEditedFaq ~ faq:", faq)
    //   return editedFaq._id === '' ? data : faq
    // }) ]);
    else console.log('Error saving faq');
  }

  const saveEditedFaq = async (editedFaq) => {
    const data = await editFaq(editedFaq);
    if (data) setFaqList([ ...faqList.map(faq => faq._id === editedFaq._id ? data : faq) ]);
    else console.log('Error saving faq');
  }

  const removeFaq = async (faqId) => {
    const data = await deleteFaq(faqId);
    if (data) setFaqList([ ...faqList.filter(faq => faq._id !== faqId) ]);
    else console.log('Error deleting faq');
  }

  return (
    <div className="faqs">
      <div className="faq-header">
        <div className="faq-header-title">
          <TitleWithSeparator title="Ofte stilte spørsmål" />
          {loggedInUser && loggedInUser.roles.includes('admin') && <div className='pointer'><FontAwesomeIcon icon={faPlus} size='2x' onClick={newFaq} /></div>}
        </div>
        <p>Ikke nøl med å ta kontakt dersom du ikke finner det du lurer på.</p>
      </div>
      <FaqList fns={{ saveNewFaq, saveEditedFaq, removeFaq }}>{faqList}</FaqList>
    </div>
  );
};

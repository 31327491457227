import { useContext } from 'react';
import TitleWithSeparator from '../../../Separator/TitleWithSeparator';
import './orders.css';
import { LoggedInUserContext } from '../../../../context';
import { NotLoggedIn } from '../../../Errors/NotLoggedIn';
import { OrdersList } from '../../../Orders/OrdersList';

export const AdminOrdersPage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (!loggedInUser) return <div className='content-margined'><NotLoggedIn /></div>;

  document.title = 'Ordre - Administator - Badenymfene Babysvømming';

  return (
    <div className="content-margined">
      <div className='orders-page'>
        <TitleWithSeparator title="Ordre" />
        <OrdersList />
      </div>
    </div>
  );
}

export const hasToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (!token || !token.expires_at || token.expires_at < Date.now() || !token.id_token) {
    localStorage.removeItem('token');
    return false;
  }
  return true;
}

export const fetchWithOptionalVippsToken = (url, args) => {
  if (!hasToken()) return fetch(url, args);
  return fetchWithVippsToken(url, args);
}

export const fetchWithVippsToken = (url, args) => {
  if (!hasToken()) {
    console.log('Not logged in');
    return;
  }
  const token = JSON.parse(localStorage.getItem('token'));
  // const access_token = token.access_token;
  const id_token = token.id_token;
  const defaultOptions = {
    headers: {
      Authorization: `${token.token_type} ${id_token}`
    }
  }
  if (args && args.headers) {
    args.headers.Authorization = `${token.token_type} ${id_token}`;
  }
  const options = { ...defaultOptions, ...args };
  return fetch(url, options);
}

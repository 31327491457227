import { useContext, useEffect, useState } from 'react';
import './faqListItem.css';
import { LoggedInUserContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export const FaqListItem = ({ children, fns }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [faq, setFaq] = useState(children);
  const [editMode, setEditMode] = useState(false);
  // const [clickCounter, setClickCounter] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(editMode);
  }, [editMode]);

  const rowClick = (e) => {
    if (!editMode) {
      setExpanded(!expanded);
      return;
    }
  }

  return children && (
    <>
      <div className="faq-list-item box">
        <div className="top">
          {!editMode && loggedInUser && loggedInUser.roles.includes('admin') &&
            <div className="edit-icon pointer" onClick={() => setEditMode(true)}>
              <FontAwesomeIcon icon={faPenToSquare} size='2x' color='#0146f6' />
            </div>
          }
          <div className="question pointer" onClick={rowClick}>
            {editMode && loggedInUser && loggedInUser.roles.includes('admin') ?
              <input
                type="text"
                placeholder='Spørsmål'
                value={faq.question}
                onChange={e => setFaq({ ...faq, question: e.target.value })}
              />
              :
              <>
                {/* {faq.question ? <p>{faq.question}</p> : <><FontAwesomeIcon icon={faArrowLeft} /><p>Klikk for å legge til spørsmål</p></>} */}
                <p>{faq.question || 'Nytt spørsmål'}</p>
                <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight} color='#0146f6' />
              </>
            }
          </div>
        </div>
        {expanded && <div className="answer">
          {editMode && loggedInUser && loggedInUser.roles.includes('admin') ?
            <textarea
              placeholder='Svar'
              value={faq.answer}
              onChange={e => setFaq({ ...faq, answer: e.target.value })}
            />
            :
            <p className="pre-wrap">{faq.answer || 'Svar'}</p>
          }
        </div>}
        {editMode && loggedInUser && loggedInUser.roles.includes('admin') &&
          <div className="actions">
            <div className="left">
              <button className="btn" onClick={() => {
                setFaq({ ...children });
                setEditMode(false)
              }}>Avbryt</button>
              {faq && faq._id && <p className='pointer text-delete-color' onClick={() => fns.removeFaq(faq._id)}>Slett</p>}
            </div>
            <div className="right">
              <button
                className="btn"
                onClick={() => {
                  setEditMode(false);
                  if (faq._id.length < 24) {
                    fns.saveNewFaq(faq);
                  } else {
                    fns.saveEditedFaq(faq);
                  }
                  // setFaq({});
                }
              }>Lagre</button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

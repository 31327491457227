import VippsLogin from "../../Vipps/Login";
import { Modal } from "../Generic/Modal";

export const LoginModal = ({ options }) => {
  const defaultOptions = { title: 'Logg inn' };
  return (
    <Modal options={{ ...defaultOptions, ...options }}>
      <p>Du må være logget inn for å utføre denne handlingen</p>
      <VippsLogin />
    </Modal>
  );
}

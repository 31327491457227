import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";

export const fetchTermsAndConditions = async () => {
  try {
    const res = await fetch(`${api.baseUrl}/terms-and-conditions`);
    const termsAndConditions = await res.json();
    return termsAndConditions;
  } catch (error) {
    console.error(error);
  }
}

export const fetchTermAndCondition = async (termId) => {
  if (!termId || termId.length !== 24) return;
  try {
    const res = await fetch(`${api.baseUrl}/terms-and-conditions/${termId}`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const addTermAndCondition = async (newTermAndCondition) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/terms-and-conditions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newTermAndCondition)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const editTermAndCondition = async (editedTermAndCondition) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/terms-and-conditions/${editedTermAndCondition._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedTermAndCondition)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteTermAndCondition = async (termId) => {
  if (!termId || termId.length !== 24) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/terms-and-conditions/${termId}`, {
      method: 'DELETE'
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

import { useContext, useEffect, useState } from "react";
import { Modal } from "../Generic/Modal";
import './selectChildModal.css'
import Button from "../../Buttons/Button";
import { LoggedInUserContext } from "../../../context";
import { CustomSelect } from "../../Select/CustomSelect";
import { fetchMyChildren, addChild } from "../../../functions/fetch";
import { AddChild } from "../AddChild/AddChild";
import { Banner } from "../../Banner/Banner";

export const SelectChildModal = ({ options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [children, setChildren] = useState([]);
  const [addChildMode, setAddChildMode] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState(null);

  useEffect(() => {
    if (addChildMode) return;
    const getChildren = async () => {
      try {
        const res = await fetchMyChildren(loggedInUser._id);
        setChildren(res);
      } catch (error) {
        console.log(error);
      }
    }

    getChildren(children);
  }, [addChildMode]);

  const saveNewChild = async (newChild) => {
    const data = await addChild(newChild);
    if (data) {
      // options.onConfirm(data);
      setAddChildMode(false);
    }
    else console.log('Error saving child');
  }

  return (
    <Modal options={{ ...options, title: 'Velg barn' }}>
      <div className="child-modal-content">
        {loggedInUser && addChildMode ?
          <AddChild options={{ saveNewChild, finishEditing: () => setAddChildMode(false) }} />
        :
          <>
            <p>Velg barnet du ønsker skal gå på dette kurset:</p>
            <CustomSelect 
              onChange={e => {
                options.clearError();
                setSelectedChildId(e.target.value)
              }}
            >
              <option value="0" disabled>Velg barn</option>
              {children && children.length > 0 && children.map(child => (
                <option value={child._id} key={child._id}>{`${child.firstName} ${child.lastName}`}</option>
              ))}
            </CustomSelect>
            <div className="add-child-action pointer">
              <p onClick={() => setAddChildMode(true)}>Legg til nytt barn</p>
            </div>
            {options.errorMessage && <Banner type='error' message={options.errorMessage} />}
            <div className="actions">
              <Button onClickFn={() => {
                options.close();
                options.clearError();
              }}>Avbryt</Button>
              <Button bgc="#0146F6" color="#fff" onClickFn={async (e) => {
                if (!e.target.disabled) {
                  e.target.disabled = true;
                  options.clearError();
                  await options.onConfirm(selectedChildId);
                  e.target.disabled = false;
                }
              }}>Bestill kurs</Button>
            </div>
          </>
        }
      </div>
    </Modal>
  );
}

import { weekdayHelper } from '../../../functions/helpers';
import './courseCard.css';

export const CourseCards = ({ course, onClick, selectedCourseId }) => {

  const classes = course._id === selectedCourseId ? 'course-card box selected' : 'course-card box';

  return (
    <div className={classes} onClick={onClick}>
      <div className="top">
        <div>
          <p>{course.participantsAge}</p>
          <p>{course.experienceLevel}</p>
        </div>
        <div>
          <p>{weekdayHelper(new Date(course.dates[0]).getDay())}er</p>
          <p>kl {course.timeFrom} - {course.timeTo}</p>
        </div>
        <p>Oppstart: {new Date(course.dates[0]).toLocaleDateString()}</p>
        <p>{course.dates && course.dates.length > 0 ? course.dates.length : 0} ganger</p>
        <p>{course.price} kr</p>
        <p>{course.maximumParticipants - course.participants.length} ledige plasser</p>
      </div>
    </div>
  );
}


import CoursesFrontPage from '../../Courses/CoursesFrontPage';
import FrontPageHeader from '../../Headers/FrontPageHeader';
import './home.css';
import AboutCompany from '../../About/AboutCompany';
import { Faqs } from '../../Faqs/Faqs';
import { FrontPageBanner } from '../../Banner/Banner';
import { Link } from 'react-router-dom';

const HomePage = () => {
  document.title = 'Badenymfene Babysvømming';
  return (
    <div className='home'>
      {new Date() < new Date("2024-10-29 16:00") && <FrontPageBanner>
        <p>Vi har fått nye kurs hos Cathinka Guldbergs Sykehus (LHL) i Jessheim!</p>
        <p>Kursene er for 0-12 mnd og starter 22.10.</p>
        <Link to="kurs/66f517dd409b6c5ee4efc9ed" style={{ color: 'white' }}>Se kursene</Link>
      </FrontPageBanner>}
      <FrontPageHeader />
      <div className="content">
        <CoursesFrontPage />
        <Faqs />
        <AboutCompany />
      </div>
    </div>
  );
}

export default HomePage;

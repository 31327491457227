import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";


export const getUserFromToken = async () => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/me`);
    return await res.json();
  } catch (e) {
    console.log('Error fetching user', e);
  }
  // if (!hasToken()) return null;
  // const token = JSON.parse(localStorage.getItem('token'));
  // const idToken = token.id_token;
  // try {
  //   const user = await fetch(`${api.baseUrl}/users/me`, {
  //     headers: {
  //       Authorization: `${token.token_type} ${idToken}`
  //     }
  //   });
  //   return await user.json();
  // } catch (e) {
  //   console.log('Error fetching user', e);
  // }
}

export const getVippsUser = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const access_token = token.access_token;
  return fetchWithVippsToken(`${api.baseUrl}/vipps/user?at=${access_token}`);
}

export const fetchInstructors = async () => {
  try {
    const res = await fetch(`${api.baseUrl}/users/instructors`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addChild = async (child) => {
  if (!child.firstName || !child.lastName || !child.birthdate) {
    console.error('Missing required fields');
    return null;
  };
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/children`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(child)
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateChild = async (editedChild) => {
  const child = {
    _id: editedChild._id
  };
  if (editedChild.firstName) child.firstName = editedChild.firstName;
  if (editedChild.lastName) child.lastName = editedChild.lastName;
  if (editedChild.birthdate) child.birthdate = editedChild.birthdate;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/${child._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(child)
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteChild = async (childId) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/${childId}`, {
      method: 'DELETE'
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getChildren = async () => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/children`);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getChild = async (id) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/children/${id}`);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = async (usersIds = []) => {
  let url = `${api.baseUrl}/users`;
  if (usersIds.length > 0) url += `?ids=${usersIds.join(',')}`;
  try {
    const res = await fetchWithVippsToken(url);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

// export const searchUsers = async (name, categories = []) => {
//   const searchCategories = categories.length > 0 ? `&categories=${categories.join(',')}` : '';
//   try {
//     const res = await fetchWithVippsToken(`${api.baseUrl}/users?name=${name}${searchCategories}`);
//     if (res.status !== 200) throw res;
//     return await res.json();
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchCoursesFromUserIds = async (userIds) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/courses?cIds=${userIds.join(',')}`);
    if (res.status !== 200) throw res;
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}

export const fetchEditUserRoles = async (userId, roles) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ roles })
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

import { NavLink, useLocation } from 'react-router-dom';
import BadenymfeneLogo from '../Logo/BadenymfeneLogo';
import './navbar.css';
import { useContext, useState } from 'react';
import { LoggedInUserContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const path = useLocation().pathname;

  const links = [
    { text: 'Bestill kurs', path: '/kurs' },
    { text: 'Forside', path: '/' },
    { text: 'Om oss', path: '/om-oss' },
    { text: 'Regler og betingelser', path: '/regler-og-betingelser' },
    { text: 'Kontakt', path: '/kontakt' },
    { text: loggedInUser ? loggedInUser.firstName : 'Logg inn', path: loggedInUser ? '/profil' : '/login' } 
  ];

  return (
    <nav>
      <div className="navbar">
        <div className="row">
          <div className="logo">
            <h2>
              <BadenymfeneLogo />
            </h2>
          </div>
          <div className="link-list">
            <ul>
              {links.map(link => <li key={link.text} className={path === link.path ? 'active' : ''}><NavLink to={link.path}>{link.text}</NavLink></li>)}  
            </ul>
          </div>
          <div className="link-list-mobile-open-button pointer">
            <FontAwesomeIcon icon={mobileNavOpen ? faXmark : faBars} onClick={() => setMobileNavOpen(!mobileNavOpen)} />
          </div>
        </div>
        {mobileNavOpen && <div className="link-list-mobile">
          <ul>
            {links.map(link => <li onClick={() => setMobileNavOpen(false)} key={link.text}><NavLink to={link.path}>{link.text}</NavLink></li>)}
          </ul>
        </div>}
      </div>
    </nav>
  );
}

export default Navbar;

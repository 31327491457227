import './button.css';

const Button = ({ children, bgc, color, onClickFn, type = 'button', classes = '', disabled = false }) => {
  if (!children) {
    console.error('No children provided to Button');
    return;
  }
  if (typeof children !== 'string') throw Error('Children is not string');
  const classesString = classes ? 'btn ' + classes : 'btn';
  if (disabled) onClickFn = () => console.log('Button is disabled');
  return <button className={classesString} type={type} style={{ backgroundColor: bgc, color: color }} onClick={onClickFn} disabled={disabled}>{children}</button>
}

export default Button;

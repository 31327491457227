import { useContext, useState } from 'react';
import './termCondition.css';
import { LoggedInUserContext } from '../../context';
import { deleteTermAndCondition, editTermAndCondition, addTermAndCondition } from '../../functions/fetch';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown, faAngleRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export const TermCondition = ({ children }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [termCondition, setTermCondition] = useState(children);
  const [editMode, setEditMode] = useState(termCondition && termCondition._id === '');
  
  if (!termCondition) return null;

  return (
    <div className="term-condition-item" onClick={() => { if (!editMode) setEditMode(true); }}>
      {loggedInUser && loggedInUser.roles.includes('admin') && editMode ?
        <div className="term-condition-item-edit">
          {/* <input type="text" value={termCondition.text} onChange={(e) => setTermCondition({ ...termCondition, text: e.target.value })} /> */}
          <textarea value={termCondition.text} placeholder='Skriv regler, informasjon eller betingelser her' onChange={(e) => setTermCondition({ ...termCondition, text: e.target.value })} />
          <div className="actions">
            <div className="left">
              <button className="btn" onClick={() => { setTermCondition(children); setEditMode(false); }}>Avbryt</button>
              {termCondition._id && <p className='pointer text-delete-color' onClick={() => { deleteTermAndCondition(termCondition._id); setTermCondition(null); setEditMode(false); }}>Slett</p>}
            </div>
            <div className="right">
              {termCondition._id ?
                <button className="btn" onClick={() => { editTermAndCondition(termCondition); setEditMode(false); }}>Lagre</button>
              :
                <button className="btn" onClick={() => { addTermAndCondition(termCondition); setEditMode(false); }}>Lagre</button>
              }
            </div>
          </div>
        </div>
        :
        <p>{termCondition.text}</p>
      }
    </div>
  );
};

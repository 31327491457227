import { api } from "../../config";
import { fetchWithOptionalVippsToken } from "../auth";

export const getInstructors = async () => {
  try {
    const res = await fetchWithOptionalVippsToken(`${api.baseUrl}/users/instructors`);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
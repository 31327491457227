import './participantsList.css';
import Button from "../../Buttons/Button";
import { Modal } from "../Generic/Modal";
import { weekdayHelper } from '../../../functions/helpers';

export const ParticipantsListModal = ({ options, children }) => {

  if (!options.courseListCourse) {
    return null;
  }

  const participants = [];
  options.courseListCourse.populatedParticipants.forEach((profile, profileIndex) => {
    profile.children.forEach((child, childIndex) => {
      if (options.courseListCourse.participants.map(p => p._id).includes(child._id)) {
        participants.push({
          profile: profile,
          child: child
        })
      }
    })
  });

  participants.sort((a, b) => a.child.firstName.localeCompare(b.child.firstName));

  const modalId = 'participants-list-modal';

  const courseInstructor = options.instructors.find(instructor => instructor.id === options.courseListCourse.instructors[0]);

  return (
    <Modal options={{ ...options, modalId: modalId, title: 'Kursliste' }}>
      <div className="participants-list">
        <div className="course-list-header">
          <div className="top">
            <h3 className='' style={{ margin: '0' }}>{options.courseListCourse.place.name}</h3>
            <p style={{ margin: '0' }}>{courseInstructor ? courseInstructor.name : ''}</p>
          </div>
          <div className="bottom">
            <h4 className='' style={{ margin: '0' }}>{weekdayHelper(new Date(options.courseListCourse.dates[0]).getDay())}er {options.courseListCourse.timeFrom} - {options.courseListCourse.timeTo} {options.courseListCourse.experienceLevel} {options.courseListCourse.participantsAge}</h4>
          </div>
        </div>
        {/* <hr /> */}
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'unset' }}>Barn</th>
              <th style={{ textAlign: 'unset' }}>Foresatt</th>
              {options.courseListCourse.dates.map(dateStr => {
                // TODO: Some dates are creating a new date object
                const date = new Date(dateStr);
                return (<th align='center' width="50px" key={date.getTime()}>{date.getDate()}.{date.getMonth() + 1}</th>)})}
            </tr>
          </thead>
          <tbody>
          {participants.map((p, pIndex) => {
            const child = p.child;
            const profile = p.profile;
            return <tr key={child._id} style={{ backgroundColor: pIndex % 2 === 0 ? '#fff' : '#f5f5f5' }}>
              <td>{child.firstName} {child.lastName}<br />{new Date(child.birthdate).toLocaleDateString()}</td>
              <td>
                {profile.parents.map((parent, parentIndex) => <span key={parent._id} style={{ display: 'block' }}>{parent.firstName} {parent.lastName} - <a href={`tel:${parent.phone.substring(2)}`}>{parent.phone.substring(2)}</a><br /><a className='parent-email' href={`mailto:${parent.email}`}>{parent.email}</a></span>)}
              </td>
              {options.courseListCourse.dates.map(date => <td
                align='center'
                width="50px"
                key={`square-${new Date(date).getTime()}`}>
                  <div className='course-list-check-square'></div>
                </td>)}
            </tr>
          })}
          </tbody>
        </table>
      </div>
      <div className="copy-section">
        <div className="copy-phone-numbers">
          <input id="copy-phone-numbers" className="copy-input" type="text" value={participants.map(p => p.profile.parents.map(p => `+${p.phone}`)).join(', ')} readOnly />
          <Button onClickFn={() => {
            const el = document.getElementById('copy-phone-numbers');
            el.select();
            el.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(el.value);
          }
          }>Kopier telefonnummere</Button>
        </div>
        <div className="copy-email-addresses">
          <input id="copy-email-addresses" className="copy-input" type="text" value={participants.map(p => p.profile.parents.map(p => `${p.firstName} ${p.lastName} <${p.email}>`)).join(', ')} readOnly />
          <Button onClickFn={() => {
            const el = document.getElementById('copy-email-addresses');
            el.select();
            el.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(el.value);
          }
          }>Kopier epost-adresser</Button>
        </div>
      </div>
      <div className="actions">
        <Button onClickFn={options.close}>Lukk</Button>
        <Button onClickFn={() => {
          const printContent = document.querySelector(`#${modalId} .participants-list`).innerHTML;
          const w = window.open();
          const style = document.createElement('style');
          style.innerHTML = `@page {
  size: A4 landscape;
  margin: 0;
}
body {
  margin: 15px;
}
h3.course-list-header {
  font-size: 2em;
}
h4.course-list-header {
  font-size: 1.2em;
}
.course-list-header {
  margin: 0;
}
hr {
  margin: 5px 0;
  border: none;
  border-top-color: #d9d9d9;
  border-top-width: 2px;
  border-top-style: solid;
}
.profile .child p {
  margin: 0;
}
.course-list-check-square {
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  border-top: 2px solid #d9d9d9;
  margin-top: 10px;
}
thead {
  border-bottom: 1px solid #d9d9d9;
}
tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
tbody td a.parent-email {
  display: none;
}
tbody td a {
  text-decoration: none;
  color: #000;
}
thead th {
  font-weight: unset;
  padding: 10px 5px 5px 5px;
}
thead th:nth-child(n+3) {
  text-align: center;
}
th,
td {
  padding: 5px;
  margin: 0;
}
.course-list-header .top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}`;
          w.document.write(printContent);
          w.document.head.appendChild(style);
          w.print();
          w.close();
          options.close();
        }}>Skriv ut..</Button>
      </div>
    </Modal>
  );
}

import { useEffect, useState } from "react";
import './editParticipantModal.css';
import { Modal } from "../Generic/Modal";
import { fetchCoursesForPlace, fetchPlaces } from "../../../functions/fetch";
import { CustomSelect } from "../../Select/CustomSelect";
import Button from "../../Buttons/Button";
import { CourseCards } from "../../Courses/CourseCard/CourseCard";
import { addParticipant, deleteParticipant, moveParticipant } from "../../../functions/fetch/courses";

export const EditParticipantModal = ({ options }) => {
  const [places, setPlaces] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [courses, setCourses] = useState(null);
  const [moveToCourse, setMoveToCourse] = useState(options.course ? options.course._id : null);

  useEffect(() => {
    if (!places) {
      const getPlaces = async () => {
        const places = await fetchPlaces();
        setPlaces(places);
      }
      getPlaces();
    }
  }, []);

  useEffect(() => {
    const getCourses = async () => {
      const courses = await fetchCoursesForPlace(selectedPlace);
      setCourses(courses);
    }
    if (selectedPlace) getCourses();
  }, [selectedPlace]);

  const modifyParticipant = async () => {
    const fromCourse = options.course;
    const toCourse = moveToCourse;
    if (!toCourse) {
      console.log('Missing course to move to');
      return;
    }
    const participant = options.participant;
    if (!participant) {
      console.log('Missing participant');
      return;
    }
    if (!fromCourse) {
      try {
        const res = await addParticipant(participant._id, toCourse);
        if (res) {
          options.updateHistory();
          options.close();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await moveParticipant(participant._id, fromCourse._id, toCourse);
        if (res) {
          options.updateHistory();
          options.close();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeParticipant = async () => {
    if (!options.course) {
      options.close();
    } else {
      const participant = options.participant;
      const res = await deleteParticipant(participant._id, options.course._id);
      if (res) {
        options.updateHistory();
        options.close();
      }
    }
  };

  return (
    <Modal options={{ ...options, title: 'Legg til/endre kurspåmelding' }}>
    <div className="child-courses-modal-edit">
      {options.course && <p>Flytt <strong>{options.participant.firstName} {options.participant.lastName}</strong> til et annet kurs.</p>}
      {!options.course && <p>Legg til <strong>{options.participant.firstName} {options.participant.lastName}</strong> i et kurs.</p>}
      <div className="places">
        <div className="custom-select-wrapper">
            <CustomSelect
              defaultText='Velg kurssted'
              onChange={e => setSelectedPlace(e.target.value)}
            >
              <option value="0" disabled>Velg kurssted</option>
              {places && places.map(place => (
                <option value={place._id} key={place._id}>{place.name}</option>
              ))}
            </CustomSelect>
        </div>
      </div>
      {courses && <div className="courses">
        {courses.map(course => <CourseCards
          key={course._id}
          course={course}
          selectedCourseId={moveToCourse}
          onClick={() => setMoveToCourse(course._id)}
        />)}
      </div>}
      <div className="actions">
        <div className="left">
          <Button onClickFn={options.close}>Avbryt</Button>
          <p className="pointer text-delete-color" onClick={removeParticipant}>Fjern fra kurs</p>
        </div>
        <div className="right">
          <Button onClickFn={modifyParticipant}>Lagre</Button>
        </div>
      </div>
    </div>
    </Modal>
  );
}

import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";

export const getAllOrders = async () => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/payments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

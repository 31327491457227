import { useState } from "react";
import Button from "../../Buttons/Button";

export const AddChild = ({ options }) => {
  const [newChild, setNewChild] = useState(options.child || {});

  const saveNewChild = async (e) => {
    e.preventDefault();
    if (!newChild.firstName || !newChild.lastName || !newChild.birthdate) {
      console.error('Missing required fields');
      return null;
    };
    if (newChild._id) {
      await options.updateChild(newChild);
    } else {
      await options.saveNewChild(newChild);
    }
  }

  const handleNameChange = (e, field) => {
    if (!e.target.value) {
      setNewChild({ ...newChild, [field]: '' });
      return;
    }
    const valueLowerCase = e.target.value.toLowerCase();
    const nameSpace = valueLowerCase.split(' ').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
    const nameDash = nameSpace.split('-').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join('-');
    setNewChild({ ...newChild, [field]: nameDash });
  }

  return (
    <form className="child-modal-edit">
      <div className="child-names">
        <div>
          <label htmlFor="child-add-modal-firstName">Fornavn</label>
          <input id="child-add-modal-firstName" type="text" name="firstName" placeholder="Fornavn" required value={newChild.firstName} onChange={e => handleNameChange(e, 'firstName')} />
        </div>
        <div>
          <label htmlFor="child-add-modal-lastName">Mellomnavn/Etternavn</label>
          <input id="child-add-modal-lastName" type="text" name="lastName" placeholder="Etternavn" required value={newChild.lastName} onChange={e => handleNameChange(e, 'lastName')} />
        </div>
      </div>
      <div className="child-date-of-birth">
        <div>
          <label htmlFor="child-add-modal-birthdate">Fødselsdato</label>
          <input id="child-add-modal-birthdate" type="date" name="birthdate" placeholder="Fødselsdato" required value={newChild.birthdate ? new Date(newChild.birthdate).toISOString().substring(0, 10) : ''} onChange={e => setNewChild({ ...newChild, birthdate: e.target.value })} />
        </div>
      </div>
      <div className="actions">
        <div className="left">
          <Button onClickFn={() => options.finishEditing()}>Avbryt</Button>
          {newChild && options.deleteChild && <p className='pointer text-delete-color' onClick={() => options.deleteChild(newChild._id)}>Slett</p>}
        </div>
        <div className="right">
          <Button bgc="#0146F6" color="#fff" type="submit" onClickFn={(e) => saveNewChild(e)}>Lagre</Button>
        </div>
      </div>
    </form>
  );
}

import { useContext } from "react";
import { LoggedInUserContext } from "../../../context";
import { splitUrlsInString, weekdayHelper } from "../../../functions/helpers";

export const CoursesHistoryGridRowExpanded = ({ course, options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (!course) return null;
  
  const duration = course.dates && course.dates.length > 0 ? course.dates.length : 0;

  return <div className="expanded">
    {loggedInUser && loggedInUser.roles.includes('admin') && <div className="admin-actions">
      <p className="pointer">
        <a href="#" onClick={e => {
          e.preventDefault();
          options.openEditParticipantModal({ participant: options.user, course });
        }}>Endre påmelding</a>
      </p>
    </div>}
    <div className="grid-wrapper">
      <div className='place'>
        <div>Sted:</div>
        <div>{course.place.name}</div>
      </div>
      <div className='age'>
        <div>Alder:</div>
        <div>{course.participantsAge}</div>
      </div>
      <div className='experience-level'>
        <div>Nivå:</div>
        <div>{course.experienceLevel}</div>
      </div>
      <div className='time'>
        <div>Tidspunkt:</div>
        <div>{course.timeFrom} - {course.timeTo}</div>
      </div>
      <div className='day'>
        <div>Dag:</div>
        <div>{course.dates && course.dates.length > 0 ? weekdayHelper(new Date(course.dates[0]).getDay()) : weekdayHelper(new Date().getDay())}</div>
      </div>
      <div className='dates'>
        <div>{duration > 1 ? 'Datoer' : 'Dato'}:</div>
        <div>{course.dates && course.dates.length && course.dates.map(date => (<div key={new Date(date).getTime()}>{new Date(date).toLocaleDateString()}</div>))}</div>
      </div>
      <div className='duration'>
        <div>Varighet:</div>
        <div>{duration} {duration > 1 ? 'ganger' : 'gang'}</div>
      </div>
      <div className='instructors'>
        <div>Instruktør:</div>
        <div>{options.instructorsForCourse && options.instructorsForCourse.length > 0 ? options.instructorsForCourse.map(instructor => (instructor) ? `${instructor.name}` : 'Foreløpig ukjent') : "Foreløpig ukjent"}</div>
      </div>
      {course.info && <div className="information">
        <div>Informasjon:</div>
        <div className="pre-wrap overflow-wrap">{splitUrlsInString(course.info).map((strSlice, i) => {
          const strSliceLowerCase = strSlice.toLowerCase();
          if (strSliceLowerCase.match(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i)) {
            return <a key={`info-slice-${i}`} href={strSliceLowerCase} target="_blank" rel="noopener noreferrer">{strSliceLowerCase}</a>;
          }
          return strSlice;
        })}</div>
      </div>}
    </div>
    {loggedInUser && loggedInUser.roles.includes('admin') && <div>
      <p>Kurs-ID: {course._id}</p>
    </div>}
  </div>
}

import { NavLink } from 'react-router-dom';
import './adminProfileNav.css';
import { useContext } from 'react';
import { LoggedInUserContext } from '../../context';

export const AdminProfileNav = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  const links = [
    { text: 'Brukere', path: '/admin/brukere' },
    { text: 'Ordre', path: '/admin/ordre' },
  ];

  if (!loggedInUser || !loggedInUser.roles.includes('admin')) return null;

  return (
    <nav className="profile-admin-navbar">
      <div className="row">
        <div className="link-list">
          <ul>
            {links.map(link => <li key={link.text}><NavLink to={link.path} className={link.path.length === 0 ? 'disabled' : ''}>{link.text}</NavLink></li>)}  
          </ul>
        </div>
      </div>
    </nav>
  );
}

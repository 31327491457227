import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";

export const getFaqs = async () => {
  try {
    const res = await fetch(`${api.baseUrl}/faqs`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const getFaq = async (faqId) => {
  if (!faqId || faqId.length !== 24) return;
  try {
    const res = await fetch(`${api.baseUrl}/faqs/${faqId}`);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const addFaq = async (newFaq) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/faqs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newFaq)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const editFaq = async (editedFaq) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/faqs/${editedFaq._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedFaq)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteFaq = async (faqId) => {
  if (!faqId || faqId.length !== 24) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/faqs/${faqId}`, {
      method: 'DELETE'
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

import './bubblesvg.css';

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const Bubble = ({ ...props }) => {
  const duration = randomIntFromInterval(5, 10) + "s";
  // const delay = Math.floor(Math.random() * 2)+"s";
  return <div className="bubble" style={{
      animationDuration: duration,
      animationTimingFunction: 'ease-in',
      animationIterationCount: 'infinite',
      animationName: 'bubble-side-to-side'
    }}>
    <svg viewBox={`0 0 60 60`} height={props.diameter} width={props.diameter}  xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle
          cx={30}
          cy={30}
          r={30}
          fill={props.fill}
        />
        <path
          d="M19.424 11.628C14.618 13.808 8.773 21.64 9.007 31.853"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          stroke="#fff"
          strokeWidth="6px"
        />
        <circle cx={27} cy={8} r={4} fill="#fff" />
      </g>
    </svg>
  </div>
}

const BubbleGradient = ({ ...props }) => {
  const duration = randomIntFromInterval(5, 15) + "s";
  return <div className="bubble" style={{
      animationDuration: duration,
      animationTimingFunction: 'ease-in',
      animationIterationCount: 'infinite',
      animationName: 'bubble-side-to-side'
    }}>
    {/* style="animation: 5s linear 0s infinite normal both running bubble-side-to-side;" */}
    <svg
      viewBox="0 0 60 60"
      width={props.diameter}
      height={props.diameter}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1={30}
          y1={0}
          x2={30}
          y2={60}
          id="a"
          gradientTransform="matrix(.74938 -.66214 .65222 .73816 -11.048 26.434)"
        >
          <stop offset={0} stopColor={props.gradient.start} />
          <stop offset={1} stopColor={props.gradient.stop} />
        </linearGradient>
      </defs>
      <circle cx={30} r={30} cy={30} fill="url(#a)" />
      <path
        d="M19.424 12C14.618 14.18 8.773 22.012 9.007 32.225"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
        paintOrder="stroke"
        strokeWidth="6px"
        stroke="#fff"
      />
      <circle cx={27} cy={8} r={4} fill="#fff" />
    </svg>
  </div>
}

export {
  Bubble,
  BubbleGradient
}

import { useContext, useEffect, useState } from 'react';
import TitleWithSeparator from '../Separator/TitleWithSeparator';
import './contactForm.css';
import { LoggedInUserContext } from '../../context';
import Button from '../Buttons/Button';
import { sendContactForm } from '../../functions/fetch/contact';

const Contact = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submittedForm, setSubmittedForm] = useState(false);
  const [submittedFormText] = useState("Takk for henvendelsen! Vi vil svare deg så fort som mulig.");
  const [submittedFormTextError] = useState("Beklager, det oppstod en feil. Vennligst prøv igjen.");

  useEffect(() => {
    if (!loggedInUser) return;
    setFormData({
      name: loggedInUser.firstName && loggedInUser.lastName ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : '',
      email: loggedInUser.email,
      message: '',
    });
  }, [loggedInUser]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submittedForm) return;
    setSubmittedForm(true);
    try {
      const res = await sendContactForm(formData);
      // console.log(res);
    } catch (error) {
      setSubmittedForm(false);
    }
  }

  return (
    <div className="contact-form-wrapper">
      <TitleWithSeparator title="Kontakt oss" />
      <div className="description">
        <p>Send oss en mail til <a href="mailto:post@badenymfene.no">post@badenymfene.no</a>, ring oss på <a href="tel:+4792060758">920 60 758</a>, eller send en melding her:</p>
      </div>
      <form id="contact-form" onSubmit={handleSubmit} onChange={handleChange}>
        <div className="row">
          <input name="name" type="text" readOnly={submittedForm} defaultValue={formData.name} placeholder='Navn' autoComplete='off' required />
          <input name="email" type="email" readOnly={submittedForm} defaultValue={formData.email} placeholder='E-postadresse' autoComplete='off' required />
        </div>
        <div className="row">
          <textarea name="message" rows="7" placeholder='Melding' readOnly={submittedForm} required></textarea>
        </div>
        {submittedForm && <p className="info-text">{submittedFormText}</p>}
        <div className="row">
          <Button type="submit" bgc="#0146F6" color="white" disabled={submittedForm}>Send melding</Button>
        </div>
      </form>
    </div>
  );
}

export default Contact;

import './titleWithSeparator.css';

const TitleWithSeparator = (props) => (
  <div className="title-with-separator">
    <h1 className="title">{props.title}</h1>
    <div className='separator'></div>
  </div>
);

export default TitleWithSeparator;

import { useState, useEffect } from 'react';
import { PlaceCards } from '../Cards/PlaceCard';
import TitleWithSeparator from '../Separator/TitleWithSeparator';
import './coursesFrontPage.css';
import { fetchPlaces } from '../../functions/fetch';

const CoursesFrontPage = () => {
  const [places, setPlaces] = useState(null);

  useEffect(() => {
    const getPlaces = async () => {
      if (places) return;
      const data = await fetchPlaces();
      if (data) {
        setPlaces(data);
      } else {
        console.error('Could not fetch front page course places');
      }
    }

    getPlaces();
  }, [places]);

  return (
    <div className='places'>
      <TitleWithSeparator title="Kurssteder" />
      <div className="description">
        <p>Svømming skal være gøy, og lærerikt. Gjør barnet ditt kjent og trygg i vannet allerede som babyer, samtidig som dere skaper nye minner og tillit sammen.</p>
      </div>
      <div className="course-cards">
        {places && Array.isArray(places) && places.length > 0 && places.map((place, i) =>
          <PlaceCards key={i} place={place} />
        )}
      </div>
    </div>
  );
}

export default CoursesFrontPage;

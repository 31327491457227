import { useContext } from 'react';
import { UserSearch } from '../../../Input/UserSearch/UserSearch';
import TitleWithSeparator from '../../../Separator/TitleWithSeparator';
import './users.css';
import { LoggedInUserContext } from '../../../../context';
import { NotLoggedIn } from '../../../Errors/NotLoggedIn';

export const AdminUsersPage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (!loggedInUser) return <div className='content-margined'><NotLoggedIn /></div>;

  document.title = 'Brukere - Administator - Badenymfene Babysvømming';

  return (
    <div className="content-margined">
      <div className='users-page'>
        <TitleWithSeparator title="Brukere" />
        <p>Her kan du søke etter alle registrerte brukere og vise deres profil.</p>
        <UserSearch />
      </div>
    </div>
  );
}

import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";

export const searchUsers = async (name, categories = []) => {
  const searchCategories = categories.length > 0 ? `&categories=${categories.join(',')}` : '';
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/search/users?name=${name}${searchCategories}`);
    if (res.status !== 200) throw res;
    return await res.json();
  } catch (error) {
    throw error;
  }
};

import { useState } from 'react';
import './coursesHistoryGrid.css';
import { CoursesHistoryGridRow } from './CoursesHistoryGridRow';

export const CoursesHistoryGrid = ({ options }) => {

  if (!options.history || options.history.length === 0) return <div className='box'>Ingen kurshistorikk</div>;

  return (
    <div className="courses-history-grid">
      <div className="courses-history-grid-row courses-history-grid-header">
        <div className='expand-btn'></div>
        <div className='child'>Barn</div>
        <div className='place'>Sted</div>
        <div className='age'>Alder</div>
        <div className='experience-level'>Nivå</div>
        <div className='time'>Tidspunkt</div>
        <div className='day'>Dag</div>
        <div className='dates'>Startdato</div>
        <div className='duration'>Varighet</div>
        <div className='status'>Status</div>
      </div>
      {options.history.map((history, i) => history.courses.length > 0 && history.courses.map((course, i) => <CoursesHistoryGridRow
        key={i}
        course={course}
        options={{
          user: history.user,
          instructors: options.instructors,
          updateHistory: options.updateHistory
        }}
      />))}
    </div>
  );
}

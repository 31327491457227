import './profile.css';
import { useContext, useEffect, useState } from 'react';
import { DateTime } from "luxon";
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import { LoggedInUserContext } from '../../../context';
import { AdminProfileNav } from '../../Navbar/AdminProfileNav';
import PersonCard from '../../Cards/PersonCard';
import { addChild, deleteChild, fetchCoursesFromUserIds, fetchEditUserRoles, fetchInstructors, fetchProfile, fetchProfileFromParentId, updateChild } from '../../../functions/fetch';
import { NotLoggedIn } from '../../Errors/NotLoggedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { NotAuthorized } from '../../Errors/NotAuthorized';
import { AddChildModal } from '../../Modal/AddChild/AddChildModal';
import { CustomCheckbox } from '../../CustomCheckbox/CustomCheckbox';
import { NotFound } from '../../Errors/NotFound';
import { CoursesHistoryGrid } from '../../Courses/CoursesHistoryGrid/CoursesHistoryGrid';
import { EditParticipantModal } from '../../Modal/AddChild/EditParticipantModal';

export const ProfilePage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [populatedProfile, setPopulatedProfile] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [editingChild, setEditingChild] = useState(null);
  const [courseHistory, setCourseHistory] = useState(null);
  const [toggleInstructorRoleForUserId, setToggleInstructorRoleForUserId] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [updateHistory, setUpdateHistory] = useState(false);
  const [openEditParticipantModal, setOpenEditParticipantModal] = useState(false);
  const [childToAddToCourse, setChildToAddToCourse] = useState(null);
  const params = useParams();

  document.title = 'Profil - Badenymfene Babysvømming';

  useEffect(() => {
    const getProfile = async () => {
      if (!loggedInUser) return;
      if ((params['*'].length > 0 && params['*'] !== loggedInUser._id) && !loggedInUser.roles.includes('admin')) {
        setNotAuthorized(true);
        return;
      };
      if (params['*'] && params['*'].length !== 24) {
        setNotFound(true);
        return;
      }
      const profileId = params['*'] ? params['*'] : loggedInUser._id;
      const profile = params['*'].length === 0 ? await fetchProfileFromParentId(profileId) : await fetchProfile(profileId, true);
      if (!profile) {
        setNotAuthorized(true);
        return;
      };
      if (profile.statusCode && profile.statusCode === 404) {
        setNotFound(true);
        return;
      }
      setPopulatedProfile(profile);
    }

    getProfile();
  }, [loggedInUser]);

  useEffect(() => {
    const getCourseHistory = async () => {
      if (!populatedProfile || !populatedProfile.children) return;
      const history = await fetchCoursesFromUserIds(populatedProfile.children.map(c => c._id));
      setCourseHistory(history);
      setUpdateHistory(false);
    }
    getCourseHistory();
  }, [populatedProfile, updateHistory]);

  useEffect(() => {
    if (!courseHistory || instructors.length > 0) return;
    const getInstructors = async () => {
      const instructors = await fetchInstructors();
      setInstructors(instructors);
    }
    getInstructors();
  }, [courseHistory]);

  useEffect(() => {
    if (!populatedProfile || !populatedProfile.parents || populatedProfile.parents.length === 0) return;
    if (!toggleInstructorRoleForUserId) return;
    const editRole = async () => {
      try {
        const parent = populatedProfile.parents.length === 1 ? populatedProfile.parents[0] : populatedProfile.parents.find(p => p._id === toggleInstructorRoleForUserId);
        if (!parent) return;
        const editedUser = await fetchEditUserRoles(parent._id, parent.roles);
        setToggleInstructorRoleForUserId(null);
        // Set new data just to be safe
        setPopulatedProfile({ ...populatedProfile, parents: populatedProfile.parents.map(p => {
          if (p._id === parent._id) {
            return editedUser;
          }
          return p;
        })});
      } catch (error) {
        console.log(error);
      }
    }
    editRole();
  }, [populatedProfile, toggleInstructorRoleForUserId]);

  if (!loggedInUser) return <div className='content-margined'><NotLoggedIn /></div>;
  if (notAuthorized) return <div className='content-margined'><NotAuthorized /></div>;
  if (notFound) return <div className='content-margined'><NotFound /></div>;


  const openChildModal = (childId) => {
    if (childId) {
      setEditingChild(populatedProfile.children.find(c => c._id === childId));
    }
    setIsChildModalOpen(true);
  }

  const removeChild = async (childId) => {
    if (!window.confirm('Er du sikker på at du vil slette denne brukeren?')) return;
    const deletedChildRes = await deleteChild(childId);
    if (deletedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: populatedProfile.children.filter(c => c._id !== childId) });
      setIsChildModalOpen(false);
    }
  }

  const saveNewChild = async (newChild) => {
    const addedChildRes = await addChild(newChild);
    if (addedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: [...populatedProfile.children, addedChildRes] });
      setIsChildModalOpen(false)
    }
  }

  const editChild = async (child) => {
    const updatedChildRes = await updateChild(child);
    if (updatedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: populatedProfile.children.map(c => {
        if (c._id === child._id) {
          return { ...c, firstName: child.firstName, lastName: child.lastName, birthdate: child.birthdate };
        } else {
          return c;
        }})});
      setIsChildModalOpen(false)
    }
  }

  const toggleUserInstructorRole = async (e, parent) => {
    const checked = e.target.checked;
    setPopulatedProfile({ ...populatedProfile, parents: populatedProfile.parents.map(p => {
      if (p._id === parent._id) {
        if (checked) {
          return { ...p, roles: [...p.roles, 'instructor'] };
        } else {
          return { ...p, roles: p.roles.filter(r => r !== 'instructor') };
        }
      }
      return p;
    })});
    setToggleInstructorRoleForUserId(parent._id);
  }


  return (
    <div className="profile-page">
      {loggedInUser.roles.includes('admin') &&
        <AdminProfileNav />
      }
      <div className="content-margined">
        <TitleWithSeparator title="Min profil" />
        <div className="persons">
          <div className="parents">
            <h3>Foresatte</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.parents.map((parent) => <PersonCard key={parent._id} user={parent}>
                <h4>{parent.firstName} {parent.lastName}</h4>
                <p>{parent.email}</p>
                <p>{parent.phone.substring(2)}</p>
                {loggedInUser.roles.includes('admin') && <div className="parent-roles">
                  <CustomCheckbox options={{
                    checked: parent.roles.includes('instructor'),
                    value: 'instructor',
                    label: 'Instruktør',
                    onChange: (e) => toggleUserInstructorRole(e, parent)
                  }} />
                </div>}
              </PersonCard>)}
            </div>
            <p className='info-text'>Dersom du ønsker å endre opplysningene dine, må det gjøres hos Vipps.</p>
          </div>
          <div className="children">
            <h3>Barn</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.children.map((child) => {
                const birthdate = DateTime.fromISO(child.birthdate);
                const today = DateTime.now().setZone('Europe/Oslo');
                const diff = today.diff(birthdate, ['years', 'months', 'days']);
                const diffTime = diff.toMillis();
                let ageStr = '';
                if (diffTime >= 0) {
                  const diffObject = diff.toObject();
                  diffObject.days = parseInt(diffObject.days);
                  if (diffObject.years > 0) ageStr += `${diffObject.years} år`;
                  if (diffObject.months && diffObject.years < 2) ageStr += ` ${diffObject.months} mnd`;
                  if (diffObject.days > 0 && diffObject.years < 1 && (diffObject.years < 1 && diffObject.months < 12)) ageStr += (diffObject.days > 1) ? ` ${diffObject.days} dager` : ` ${diffObject.days} dag`;
                }

                return <PersonCard key={child._id} user={child}>
                <h4>{child.firstName} {child.lastName}</h4>
                <p>{ageStr} ({new Date(child.birthdate).toLocaleDateString()})</p>
                <div className='actions'>
                  <p onClick={() => openChildModal(child._id)}>Endre</p>
                  {loggedInUser && loggedInUser.roles.includes('admin') && <p onClick={() => {
                    setOpenEditParticipantModal(true);
                    setChildToAddToCourse(child);
                  }}>Legg til i kurs</p>}
                </div>
              </PersonCard>})}
              <PersonCard user={null}>
                <div className='add-child' onClick={() => openChildModal()}>
                  <p>Legg til barn</p>
                  <FontAwesomeIcon icon={faPlus} size='2x' />
                </div>
              </PersonCard>
            </div>
          </div>
        </div>
        <div className="course-history">
          <h3>Kurshistorikk</h3>
          {<CoursesHistoryGrid
              options={{
                history: courseHistory,
                instructors,
                updateHistory: () => setUpdateHistory(true)
              }}
            />
          }
        </div>
      </div>
      {openEditParticipantModal && <EditParticipantModal options={{
        isOpen: openEditParticipantModal,
        close: () => {
          setOpenEditParticipantModal(false);
          setChildToAddToCourse(null);
        },
        participant: childToAddToCourse,
        updateHistory: () => setUpdateHistory(true),
      }} />}
      <AddChildModal options={{
        isOpen: isChildModalOpen,
        close: () => {
          setIsChildModalOpen(false);
          setEditingChild(null);
        },
        deleteChild: removeChild,
        saveNewChild,
        updateChild: editChild,
        child: editingChild
      }} />
    </div>
  );
}


import { useEffect, useState } from "react";

export const DebounceInput = ({ options }) => {
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 350);
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  useEffect(() => {
    if (debouncedInputValue.length <= 2) return;
    options.onChange(debouncedInputValue);
  }, [debouncedInputValue]);

  return <input id={options.id} type="text" value={inputValue} onChange={handleInputChange} placeholder={options.placeholder} />;
};


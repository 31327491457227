import { api } from "../../config";
import { fetchWithOptionalVippsToken, fetchWithVippsToken } from "../auth";

export const fetchPlaces = async () => {
  try {
    const res = await fetchWithOptionalVippsToken(`${api.baseUrl}/places`);
    const data = await res.json();
    data.sort((a, b) => a.name.localeCompare(b.name));
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export const addPlace = async (newPlace) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/places`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newPlace)
    });
    if (res.status !== 200) throw new Error('Saving new place failed!');
    const savedPlace = await res.json();
    return savedPlace;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export const editPlace = async (editedPlace) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/places/${editedPlace._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedPlace)
    });
    if (res.status !== 200) throw new Error('Saving edited place failed!');
    const savedPlace = await res.json();
    return savedPlace;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export const deletePlace = async (id) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/places/${id}`, {
      method: 'DELETE'
    });
    if (res.status !== 200) throw new Error('Deleting place failed!');
    const deletedPlace = await res.json();
    return deletedPlace;
  } catch (err) {
    console.error(err);
    return false;
  }
}

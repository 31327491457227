import './coursesGrid.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { weekdayHelper } from '../../../functions/helpers';
import { CourseGridRowExpanded } from './CoursesGridRowExpanded';

export const CourseGridRow = ({ options, course }) => {
  const [expanded, setExpanded] = useState(false);
  
  if (!course) return null;

  const availableSpots = !course.participants || course.participants.length >= course.maximumParticipants ? 0 : (course.maximumParticipants || 0) - course.participants.length;

  const instructorsForCourse = course.instructors && course.instructors.length > 0 ? course.instructors.map(instructor => options.instructors.find(i => i.id === instructor)) : [];


  return (
    <div className={`course-grid-wrapper${!course.published ? ' unpublished' : ''}`}>
      <div className="course-grid-row">
        <div className="pointer" onClick={() => setExpanded(!expanded)}>
          {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </div>
        <div className="age" onClick={() => setExpanded(!expanded)}>
          <p>{course.participantsAge}</p>
        </div>
        <div className="experience-level" onClick={() => setExpanded(!expanded)}>
          <p>{course.experienceLevel}</p>
        </div>
        <div className="time" onClick={() => setExpanded(!expanded)}>
          <p>{course.timeFrom} - {course.timeTo}</p>
        </div>
        <div className="day" onClick={() => setExpanded(!expanded)}>
          <p>{course.dates && course.dates.length > 0 ? weekdayHelper(new Date(course.dates[0]).getDay()) : weekdayHelper(new Date().getDay())}</p>
        </div>
        <div className="dates" onClick={() => setExpanded(!expanded)}>
          <p>{course.dates && course.dates.length > 0 ? new Date(course.dates[0]).toLocaleDateString() : new Date().toLocaleDateString()}</p>
        </div>
        <div className="duration" onClick={() => setExpanded(!expanded)}>
          <p>{course.dates && course.dates.length > 0 ? course.dates.length : 0} ganger</p>
        </div>
        <div className="price" onClick={() => setExpanded(!expanded)}>
          <p>{course.price} kr</p>
        </div>
        <div className="available-spots" onClick={() => setExpanded(!expanded)}>
          <p>{availableSpots}</p>
        </div>
        <div className="">
          <p className={availableSpots > 0 ? 'pointer' : 'disabled'} onClick={e => availableSpots > 0 ? options.preOrderChecks(course._id) : null}>{availableSpots > 0 ? 'Bestill kurs' : 'Kurset er fullt'}</p>
        </div>
      </div>
      {expanded && <CourseGridRowExpanded course={course} options={{ openParticipantsList: options.openParticipantsList, openCourseModal: options.openCourseModal, instructorsForCourse }} />}
    </div>
  );
}

import VippsLogin from '../Vipps/Login';
import './notLoggedIn.css';

export const NotLoggedIn = ({ children, showLoginButton = true }) => {
  return (
    <div className="error box">
      <p>{children || 'Du må være logget inn for å se denne siden'}</p>
      {showLoginButton && <>
        <div className="login-button">
          <VippsLogin />
        </div>
      </>}
    </div>
  );
}

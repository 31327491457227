import { useState } from 'react';
import './banner.css';
import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FrontPageBanner = ({ children, options = {} }) => {
  const localStorageKey = options.localStorageKey ? options.localStorageKey : null;
  const [dismissed, setDismissed] = useState(localStorage.getItem(localStorageKey));

  const dismiss = () => {
    if (options.localStorageKey) localStorage.setItem(localStorageKey, true);
    setDismissed(true);
  }

  const styles = {};
  if (options.backgroundColor) styles.backgroundColor = options.backgroundColor;
  if (options.color) styles.color = options.color;

  if (!children) {
    console.error('No children provided to Banner');
    return null;
  }
  const classes = options.classes ? 'fp-banner ' + options.classes : 'fp-banner';
  return dismissed ? null : <div className={classes}>
    {children}
    {options.dismissBtn ? <p className='close' onClick={dismiss}>Lukk</p> : null}
  </div>
}

export const Banner = ({ type, message, showIcon = true }) => {
  if (!message) return null;
  if (type !== 'error' && type !== 'success' && type !== 'warning') return null;
  let icon;
  switch (type) {
    case 'error':
      icon = faCircleExclamation;
      break;
    case 'success':
      icon = faCircleCheck;
      break;
    case 'warning':
      icon = faTriangleExclamation;
      break;
  }
  const faIcon = showIcon && icon ? <FontAwesomeIcon icon={icon} /> : null;
  return <div className={`banner ${type}`}>{faIcon}<p>{message}</p></div>
}

// export const ErrorBanner = ({ options }) => {
//   return options.message ? <div classes='error-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }

// export const SuccessBanner = ({ options }) => {
//   return options.message ? <div classes='success-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }

// export const WarningBanner = ({ options }) => {
//   return options.message ? <div classes='warning-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }

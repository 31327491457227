import { useEffect, useState } from 'react';
import './vipps-login.css';
import { SimpleSpinner } from '../Spinner/SimpleSpinner';
import { NavLink } from 'react-router-dom';
import { fetchToken } from '../../functions/fetch';

const VippsRedirect = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getToken = async () => {
    setLoading(true);
    const token = await fetchToken(window.location.search);
    setLoading(false);
    if (token) {
      window.location.replace('/');
    } else {
      setError('fetch_token_error');
    }
  }

  useEffect(() => {
    setLoading(false);
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.size) return;
    if (searchParams.get('error')) {
      handleVippsError(searchParams.get('error'));
    } else {
      getToken();
    }
  }, []);

  const handleVippsError = error => {
    switch (error) {
      case 'access_denied':
        setError('Innlogging via Vipps ble avbrutt');
        break;
      case 'outdated_app_version':
        setError('Vipps-appen er utdatert! Oppdater appen og prøv igjen');
        break;
      case 'login_required':
        setError('Vennligst logg inn i Vipps appen, og prøv igjen');
        break;
      case 'wrong_challenge':
        setError('Feil svar ble gitt i sikkerhetssjekken i Vipps-appen. Vennligst prøv igjen');
        break;
      case 'invalid_app_callback_uri':
      case 'app_callback_uri_not_registered':
        setError('Det oppstod en feil. Vennligst kontakt oss via skjema på nettsiden');
        break;
      default:
        setError('Det oppstod en ukjent feil! Vennligst prøv igjen, eller kontakt oss via skjema på nettsiden');
        break;
    }
  }

  return (
    <div className="vipps-redirect">
      {loading && <SimpleSpinner text="Vennligst vent..." />}
      {error && <div className="error">
        <p>{error}</p>
        <div className="actions">
          <NavLink to="/" reloadDocument>Gå til forsiden</NavLink>
          <NavLink to="/login" reloadDocument>Logg inn på nytt</NavLink>
        </div>
      </div>}
    </div>
  );
}

export default VippsRedirect;

const VippsButton = (props) => {

  const defaultOptions = {
    type: 'button',
    brand: 'vipps',
    language: 'no',
    variant: 'primary',
    rounded: 'false',
    verb: 'login',
    stretched: 'false',
    branded: 'true',
    loading: 'false',
    action: props => {
      console.log('Vipps button clicked! Props:', props);
    }
  };

  const options = { ...defaultOptions, ...props };

  return <vipps-mobilepay-button
      type={options.type}
      brand={options.brand}
      language={options.language}
      variant={options.variant}
      rounded={options.rounded}
      verb={options.verb}
      stretched={options.stretched}
      branded={options.branded}
      loading={options.loading}
    ></vipps-mobilepay-button>
}

export default VippsButton;
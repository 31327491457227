import { useContext, useEffect, useState } from 'react';
import './termsAndConditions.css';
import { LoggedInUserContext } from '../../../context';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import { fetchTermsAndConditions } from '../../../functions/fetch';
import { TermCondition } from '../../TermCondition/TermCondition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TermsPage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [termsAndConditions, setTermsAndConditions] = useState(null);

  document.title = 'Regler og betingelser - Badenymfene Babysvømming';

  useEffect(() => {
    const fetchData = async () => {
      const terms = await fetchTermsAndConditions();
      setTermsAndConditions(terms);
    }
    fetchData();
  }, []);

  const newTermAndCondition = async (categoryIndex) => {
    const newTermAndCondition = {
      _id: '',
      category: termsAndConditions[categoryIndex].category,
      text: '',
      priority: 0,
    }
    const termsAndConditionsCopy = [...termsAndConditions];
    termsAndConditionsCopy[categoryIndex].terms.push(newTermAndCondition);
    setTermsAndConditions(termsAndConditionsCopy);
  }


  return (
    <div className="content-margined">
      <div className='terms-and-conditions'>
        {termsAndConditions && termsAndConditions.length > 0 && termsAndConditions.map((category, categoryIndex) => (
          <div key={categoryIndex} className='terms-and-conditions-category'>
            <div className="terms-and-conditions-category-header">
              <TitleWithSeparator title={category.category} />
              {loggedInUser && loggedInUser.roles.includes('admin') && <FontAwesomeIcon icon={faPlus} size='2x' onClick={() => newTermAndCondition(categoryIndex)} />}
            </div>
            <div className='terms-and-conditions-terms'>
              {category.terms && category.terms.length > 0 && category.terms.map((term, termIndex) => (
                <TermCondition key={termIndex}>{{ ...term, category: category.category }}</TermCondition>
              ))}
            </div>
          </div>
        ))}
        {(!termsAndConditions || termsAndConditions.length === 0) && <p>Her mangler data</p>}
      </div>
    </div>
  );
}

export default TermsPage;

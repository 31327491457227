import './customSelect.css'

export const CustomSelect = ({ children, onChange, style, name = "", id = "", defaultValue = "0" }) => {
  return (
    <div className="custom-select">
      <select name={name} id={id} onChange={onChange} defaultValue={defaultValue} style={style}>
        {children}
      </select>
    </div>
  );
}

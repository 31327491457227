import './faqList.css';
import { FaqListItem } from './FaqListItem';

export const FaqList = ({ children, fns }) => {

  return (
    <div className="faq-list">
      {children && children.length > 0 && children.map((item, index) => (
        <FaqListItem key={index} fns={{ ...fns }}>{item}</FaqListItem>
      ))}
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { LoggedInUserContext } from "../../../context";
import { fetchProfilesFromChildrenIds } from "../../../functions/fetch";
import { Link } from "react-router-dom";
import { splitUrlsInString } from "../../../functions/helpers";

export const CourseGridRowExpanded = ({ course, options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [participantsList, setParticipantsList] = useState([]);

  useEffect(() => {
    const getProfileLinks = async () => {
      const participantsProfiles = await fetchProfilesFromChildrenIds(course.participants.map(p => p._id), false);
      if (!participantsProfiles) return;
      const moddedParticipantArray = [];
      for (let participant of course.participants) {
        const profile = participantsProfiles.find(p => p.children.find(c => c === participant._id));
        const moddedParticipant = { ...participant, profile };
        moddedParticipantArray.push(moddedParticipant);
      }
      setParticipantsList(moddedParticipantArray);
    };

    if (loggedInUser && loggedInUser.roles.includes('admin') && course && course.participants && course.participants.length > 0) {
      getProfileLinks();
    }
  }, [course]);

  if (!course) return null;
  const availableSpots = !course.participants || course.participants.length >= course.maximumParticipants ? 0 : (course.maximumParticipants || 0) - course.participants.length;

  const courseParticipants = (loggedInUser && loggedInUser.roles.includes('admin') && participantsList.length > 0) ? participantsList : course.participants;

  // const timeFromDate = new Date(`1970-01-01 ${course.timeFrom}`);
  // const timeToDate = new Date(`1970-01-01 ${course.timeTo}`);
  // const durationDate = new Date(timeToDate.getTime() - timeFromDate.getTime());

  return <div className="expanded">
    {/* {!loggedInUser &&  */}
    {loggedInUser && (loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin')) && 
      <div className='admin-info'>
        <div>
          {course.participants && course.participants.length > 0 &&
            <p className="pointer">
              <a href="#" onClick={e => {
                e.preventDefault();
                options.openParticipantsList(course);
              }}>Kursliste</a>
            </p>}
        </div>
        <div className="edit-course-btn">
          <p className="pointer">
            <a href="#" onClick={e => {
              e.preventDefault();
              options.openCourseModal(course);
            }}>Rediger kurs</a>
          </p>
        </div>
      </div>
    }
    <div className="grid-wrapper">
      {loggedInUser && (loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin')) && 
        <div className='participants'>
          <div>Påmeldte:</div>
          <div>
            {course.participants && course.participants.length > 0 ? courseParticipants.map((participant, pIndex) => <div key={`participant-${pIndex}`}>
              {loggedInUser && loggedInUser.roles.includes('admin') && participant.profile ?
                <Link key={`participant-${pIndex}`} to={`/profil/${participant.profile._id}`}>
                  <p className='no-margin'>
                    {typeof participant !== 'string' && `${participant.firstName} ${participant.lastName}`}
                  </p>
                </Link>
              :
                <p key={`participant-${pIndex}`} className='no-margin'>
                  {typeof participant !== 'string' && `${participant.firstName} ${participant.lastName}`}
                </p>
              }
              </div>)
            :
              <p className='no-margin'>Ingen påmeldte</p>
            }
          </div>
        </div>
      }
      <div className='instructors'>
        <div>Instruktør:</div>
        <div>{options.instructorsForCourse && options.instructorsForCourse.length > 0 ? options.instructorsForCourse.map(instructor => (instructor) ? `${instructor.name}` : 'Foreløpig ukjent') : "Foreløpig ukjent"}</div>
      </div>
      <div className='experience-level'>
        <div>Nivå:</div>
        <div>{course.experienceLevel}</div>
      </div>
      <div className='time'>
        <div>Tidspunkt:</div>
        <div>{course.timeFrom} - {course.timeTo}</div>
      </div>
      <div className='dates'>
        <div>Datoer:</div>
        <div>{course.dates && course.dates.length && course.dates.map(date => (<div key={new Date(date).getTime()}>{new Date(date).toLocaleDateString()}</div>))}</div>
      </div>
      <div className='duration'>
        <div>Varighet:</div>
        <div>{course.dates && course.dates.length ? course.dates.length : 0} ganger</div>
      </div>
      <div className='price'>
        <div>Pris:</div>
        <div>{course.price} kr</div>
      </div>
      <div className='available-spots'>
        <div>Ledige plasser:</div>
        <div>{availableSpots}</div>
      </div>
      {course.info && <div className="information">
        <div>Informasjon:</div>
        <div className="pre-wrap overflow-wrap">{splitUrlsInString(course.info).map((strSlice, i) => {
          const strSliceLowerCase = strSlice.toLowerCase();
          if (strSliceLowerCase.match(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i)) {
            return <a key={`info-slice-${i}`} href={strSliceLowerCase} target="_blank" rel="noopener noreferrer">{strSliceLowerCase}</a>;
          }
          return strSlice;
        })}</div>
      </div>}
    </div>
    {loggedInUser && loggedInUser.roles.includes('admin') && <div>
      <p>Kurs ID: {course._id}</p>
    </div>}
  </div>
}
